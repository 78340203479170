
import { Component, Mixins } from 'vue-property-decorator';
import HeaderInfosMixin from '../header-infos';
import RunDocMixin from '@/mixins/data-operations/doc/run-doc-mixin';
import { tableToStorageRuns } from '@/store/modules/easy-firestore/table-to-storage-runs';
import VersionDocMixin from '@/mixins/data-operations/doc/version-doc-mixin';
import { tableToStorageRunsLight } from '@/store/modules/easy-firestore/table-to-storage-runs-light';

@Component
export default class TableToStorageRunsItemView extends Mixins(HeaderInfosMixin, VersionDocMixin, RunDocMixin) {
	moduleName: string = tableToStorageRuns.moduleName;
	moduleNameLight: string = tableToStorageRunsLight.moduleName;
	get itemTabsItems(): any {
		if (Object.keys(this.item).length === 0) return [];
		let tab = [
			this.runDetailsTab,
			this.runLogsTab,
			this.configurationTab,
			this.fullJSONTab,
			this.otherRunsTab,
			this.notesTab,
		] as any;
		if (!this.isLegacy(this.item.configuration_context)) {
			const taskTab = {
				label: 'Tasks',
				href: 'tasks',
				component: {
					name: 'task-listing',
					props: {
						tasks: this.tasksFormated(),
						status: true,
						valuesToDisplay: ['table_name', 'gcs_dest_prefix', 'gcs_dest_bucket', 'output_filename', 'sql_query'],
					},
				},
			};
			tab.splice(3, 0, taskTab);
		}
		return tab;
	}

	tasksFormated() {
		let result = [];
		for (const task of Object.keys(this.item.tasks).map((key) => this.item.tasks[key])) {
			result.push({
				...task,
				...this.item.configuration_context.tasks.find((t: any) => t.task_id === task.task_id),
			});
		}
		return result;
	}

	get runDetailsData() {
		if (this.isLegacy(this.item.configuration_context)) {
			return this.runDetailsDataLegacy;
		} else if (this.itemVersion(this.item.configuration_context) === '3') {
			return this.runDetailsDataV3;
		} else {
			return this.runDetailsDataDefault(this.item.configuration_context);
		}
	}

	get runDetailsDataLegacy() {
		return [
			{
				component: 'view-header',
				props: {
					item: this.item,
					collection: this.moduleName,
					activeHeader: true,
					viewId: this.item.firestore_conf_doc_id,
					viewType: 'run',
					description: null,
					runStatus: this.item.status,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Context',
					tooltip: true,
					description: 'Context of the Table to Storage Run',
					paramItems: [
						{
							id: 'account',
							label: 'Account',
							value: this.item.account,
						},
						{
							id: 'environment',
							label: 'Environment',
							value: this.item.environment,
						},
						{
							id: 'client_type',
							label: 'Client Type',
							value: this.getFormattedClientType(this.item.configuration_context.client_type),
						},
						{
							id: 'client_version',
							label: 'Client Version',
							value: this.getFormattedClientVersion(this.item.configuration_context.client_version),
						},
						{
							id: 'configuration_version',
							label: 'Configuration Version',
							value: this.itemVersion(this.item.configuration_context),
						},
					],
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Main parameters',
					tooltip: true,
					description: 'Main parameters of the Table to Storage Run',
					paramItems: [
						{
							id: 'firestore_conf_doc_id',
							label: 'Configuration Id',
							value: this.item.firestore_conf_doc_id,
						},
						{
							id: 'destination_bucket',
							label: 'Destination Bucket',
							value: this.item.destination_bucket,
						},
						{
							id: 'output_filename',
							label: 'Output Finame',
							value: this.item.output_filename,
						},
					],
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Run Details',
					tooltip: true,
					description: 'Details of the Table to Storage Run',
					paramItems: [
						{
							id: 'dag_type',
							label: 'Configuration Type',
							value: this.item.dag_type,
						},
						{
							id: 'job_id',
							label: 'Job Id',
							value: this.item.job_id,
						},
						{
							id: 'dag_execution_date',
							label: 'Execution Date',
							value: this.$moment(this.item.dag_execution_date, '').format('YYYY/MM/DD - HH:mm:ss'),
						},
						{
							id: 'dag_run_id',
							label: 'Run Id',
							value: this.item.dag_run_id,
						},
						{
							id: 'dag_generator_version',
							label: 'Generator Version',
							value: this.item.dag_generator_version,
						},
					],
				},
			},
		];
	}

	get runDetailsDataV3() {
		return [
			{
				component: 'view-header',
				props: {
					item: this.item,
					collection: this.moduleName,
					activeHeader: true,
					viewId: this.item.firestore_conf_doc_id,
					viewType: 'run',
					description: null,
					runStatus: this.item.status,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Context',
					tooltip: true,
					description: 'Context of the Table to Storage Run',
					paramItems: [
						{
							id: 'account',
							label: 'Account',
							value: this.item.account,
						},
						{
							id: 'environment',
							label: 'Environment',
							value: this.item.environment,
						},
						{
							id: 'client_type',
							label: 'Client Type',
							value: this.getFormattedClientType(this.item.configuration_context.client_type),
						},
						{
							id: 'client_version',
							label: 'Client Version',
							value: this.getFormattedClientVersion(this.item.configuration_context.client_version),
						},
						{
							id: 'configuration_version',
							label: 'Configuration Version',
							value: this.itemVersion(this.item.configuration_context),
						},
					],
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Main parameters',
					tooltip: true,
					description: 'Main parameters of the Table to Storage Run',
					paramItems: [
						{
							id: 'firestore_conf_doc_id',
							label: 'Configuration Id',
							value: this.item.firestore_conf_doc_id,
						},
						{
							id: 'configuration_type',
							label: 'Configuration Type',
							value: this.item.configuration_type,
						},
						{
							id: 'destination_bucket',
							label: 'Destination Bucket',
							value: this.item.destination_bucket,
						},
					],
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Run Details',
					tooltip: true,
					description: 'Details of the Table to Storage Run',
					paramItems: [
						{
							id: 'dag_type',
							label: 'Configuration Type',
							value: this.item.dag_type,
						},
						{
							id: 'job_id',
							label: 'Job Id',
							value: this.item.job_id,
						},
						{
							id: 'dag_execution_date',
							label: 'Execution Date',
							value: this.$moment(this.item.dag_execution_date, '').format('YYYY/MM/DD - HH:mm:ss'),
						},
						{
							id: 'duration',
							label: 'Duration',
							value: this.item.duration,
						},
						{
							id: 'dag_run_id',
							label: 'Run Id',
							value: this.item.dag_run_id,
						},
					],
				},
			},
		];
	}

	get configurationData() {
		if (this.isLegacy(this.item.configuration_context)) {
			return this.configurationDataLegacy;
		} else if (this.itemVersion(this.item.configuration_context) === '3') {
			return this.configurationDataV3;
		} else {
			return this.configurationDataDefault(this.item.configuration_context);
		}
	}

	get configurationDataLegacy() {
		return [
			{
				component: 'view-header',
				props: {
					item: this.item,
					activeHeader: false,
					viewId: this.item.firestore_conf_doc_id,
					viewType: 'conf',
					description: null,
					runStatus: this.item.status,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Context',
					tooltip: true,
					description: 'Context of the Table to Storage configuration',
					paramItems: [
						{
							id: 'account',
							label: 'Account',
							value: this.item.account,
						},
						{
							id: 'environment',
							label: 'Environment',
							value: this.item.environment,
						},
						{
							id: 'configuration_version',
							label: 'Configuration Version',
							value: this.itemVersion(this.item),
						},
					],
				},
			},
			{
				component: 'parameters-table',
				props: {
					tableTitle: 'Destination Storage',
					description: 'Destination Storage of the file to export',
					columns: [
						{
							label: 'Type',
							field: 'source_type',
							width: '100px',
						},
						{
							label: 'Storage ID',
							field: 'gcs_dest_bucket',
							width: '200px',
						},
						{
							label: 'Destination Folder',
							field: 'gcs_dest_prefix',
							width: '200px',
						},
						{
							label: 'Output Filename',
							field: 'output_filename',
							width: '200px',
						},
					],
					rows: [
						{
							source_type: 'GCS',
							gcs_dest_bucket: this.item.configuration_context.gcs_dest_bucket,
							gcs_dest_prefix: this.item.configuration_context.gcs_dest_prefix,
							output_filename: this.item.configuration_context.output_filename,
						},
					],
					vflexLength: 'xs9',
					lineNumbers: false,
					searchOptionsEnabled: false,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'File Parameters',
					tooltip: true,
					description: 'Parameters for the exported file',
					paramItems: [
						{
							id: 'compression',
							label: 'Compressed',
							value: this.item.configuration_context.compression,
							default: 'None',
						},
						{
							id: 'field_delimiter',
							label: 'Field Delimiter',
							value: this.item.configuration_context.field_delimiter,
							default: '|',
						},
						{
							id: 'delete_dest_bucket_content',
							label: 'Delete Destination Storage Content',
							value: this.item.configuration_context.delete_dest_bucket_content,
							default: false,
						},
					],
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Source Table',
					tooltip: true,
					description: 'SQL executed to generate the dataset to export into file',
					paramItems: [
						{
							id: 'gcp_project',
							label: 'Bigquery Project ID',
							value: this.item.configuration_context.gcp_project,
						},
						{
							id: '',
							label: 'SQL File',
							component: 'sql-viewer',
							properties: {
								id: this.item.id,
								sql: this.item.sql_query,
							},
						},
						{
							id: 'copy_table',
							label: 'Keep Table',
							value: this.item.configuration_context.copy_table,
							default: false,
						},
					],
				},
			},
			{
				component: 'parameters-table',
				displayCondition: this.item.configuration_context.copy_table,
				props: {
					tableTitle: 'Destination Table',
					description: 'The Destination Table where the dataset will be keeped',
					columns: [
						{
							label: 'Type',
							field: 'source_type',
							width: '150px',
						},
						{
							label: 'Project ID',
							field: 'dest_gcp_project_id',
						},
						{
							label: 'Dataset',
							field: 'dest_gbq_dataset',
						},
						{
							label: 'Table Name',
							field: 'dest_gbq_table',
						},
						{
							label: 'Table Suffix',
							field: 'dest_gbq_table_suffix',
						},
					],
					rows: [
						{
							source_type: 'BigQuery',
							dest_gcp_project_id: this.item.configuration_context.dest_gcp_project_id,
							dest_gbq_dataset: this.item.configuration_context.dest_gbq_dataset,
							dest_gbq_table: this.item.configuration_context.dest_gbq_table,
							dest_gbq_table_suffix: this.item.configuration_context.dest_gbq_table_suffix,
						},
					],
					vflexLength: 'xs9',
					lineNumbers: false,
					searchOptionsEnabled: false,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Update information',
					paramItems: [
						{
							id: 'updated_date',
							label: 'Updated date',
							value: this.$moment(
								this.item.configuration_context.update_date || this.item.configuration_context.updated_date,
								''
							).format('YYYY/MM/DD - HH:mm:ss'),
						},
						{
							id: 'updated_by',
							label: 'Updated by',
							value: this.item.configuration_context.updated_by,
						},
					],
				},
			},
		];
	}

	get configurationDataV3() {
		return [
			{
				component: 'view-header',
				props: {
					item: this.item,
					activeHeader: false,
					viewId: this.item.firestore_conf_doc_id,
					viewType: 'conf',
					description: null,
					runStatus: this.item.status,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Context',
					tooltip: true,
					description: 'Context of the Table to Storage configuration',
					paramItems: [
						{ id: 'account', label: 'Account', value: this.item.account },
						{ id: 'environment', label: 'Environment', value: this.item.environment },
						{
							id: 'client_type',
							label: 'Client Type',
							value: this.getFormattedClientType(this.item.configuration_context.client_type),
						},
						{
							id: 'client_version',
							label: 'Client Version',
							value: this.getFormattedClientVersion(this.item.configuration_context.client_version),
						},
						{
							id: 'configuration_version',
							label: 'Configuration Version',
							value: this.itemVersion(this.item.configuration_context),
						},
					],
				},
			},
			{
				component: 'parameters-table',
				props: {
					tableTitle: 'Default Destination Storage',
					description: 'Default Destination Storage of the file to export',
					columns: [
						{
							label: 'Type',
							field: 'source_type',
							width: '100px',
						},
						{
							label: 'Storage ID',
							field: 'gcs_dest_bucket',
							width: '200px',
						},
						{
							label: 'Destination Folder',
							field: 'gcs_dest_prefix',
							width: '200px',
						},
						{
							label: 'Project ID',
							field: 'gcp_project_id',
							width: '200px',
						},
					],
					rows: [
						{
							source_type: 'GCS',
							gcs_dest_bucket: this.item.configuration_context.gcs_dest_bucket,
							gcs_dest_prefix: this.item.configuration_context.gcs_dest_prefix,
							gcp_project_id: this.item.configuration_context.gcp_project_id,
						},
					],
					vflexLength: 'xs9',
					lineNumbers: false,
					searchOptionsEnabled: false,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Default File Parameters',
					tooltip: true,
					description: 'Default Parameters for the exported file',
					paramItems: [
						{
							id: 'print_header',
							label: 'Print Header',
							value: this.item.configuration_context.print_header,
							default: true,
						},
						{
							id: 'generate_top_file',
							label: 'Generate Top File',
							value: this.item.configuration_context.generate_top_file,
							default: false,
						},
						{
							id: 'delete_dest_bucket_content',
							label: 'Delete Dest Bucket',
							value: this.item.configuration_context.delete_dest_bucket_content,
							default: false,
						},
					],
				},
			},
			{
				component: 'parameters-list',
				displayCondition: this.item.configuration_context.copy_table,
				props: {
					groupTitle: 'Default Copy Table Parameters',
					paramItems: [
						{
							id: 'copy_table',
							label: 'Copy Table',
							value: this.item.configuration_context.copy_table,
						},
						{
							id: 'dest_gcp_project_id',
							label: 'Dest GCP Project ID',
							value: this.item.configuration_context.dest_gcp_project_id,
						},
						{
							id: 'dest_gbq_dataset',
							label: 'Dest GBQ Dataset',
							value: this.item.configuration_context.dest_gbq_dataset,
						},
						{
							id: 'dest_gbq_table_suffix',
							label: 'Dest GBQ Table Suffix',
							value: this.item.configuration_context.dest_gbq_table_suffix,
						},
					],
				},
			},
			{
				component: 'parameters-list',
				displayCondition: !this.item.configuration_context.copy_table,
				props: {
					groupTitle: 'Default Copy Table Parameters',
					paramItems: [
						{
							id: 'copy_table',
							label: 'Copy Table',
							value: 'false',
						},
					],
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Update information',
					paramItems: [
						{
							id: 'updated_date',
							label: 'Updated date',
							value: this.$moment(
								this.item.configuration_context.update_date || this.item.configuration_context.updated_date,
								''
							).format('YYYY/MM/DD - HH:mm:ss'),
						},
						{
							id: 'updated_by',
							label: 'Updated by',
							value: this.item.configuration_context.updated_by,
						},
					],
				},
			},
		];
	}
}
